import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import { Text, TextProps } from "@chakra-ui/react"

interface Props extends TextProps {
  html: string
  textStyle: string
}

export const RichText: React.FC<Props> = ({ html, textStyle = "paragraph", ...style }) => (
  <Text as="div" textStyle={textStyle} {...style}>
    {ReactHtmlParser(html)}
  </Text>
)
