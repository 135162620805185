import React from "react"
import { graphql, PageProps } from "gatsby"
import Template from "@app/components/Collection/Collection"

export type Props = PageProps<GatsbyTypes.TemplateCollectionQuery, GatsbyTypes.TemplateCollectionQueryVariables>

export const query = graphql`
  query TemplateCollection($handle: String!) {
    collection: sanityCollection(shopify: { handle: { eq: $handle } }) {
      ...SanityCollectionFragment
    }
    content: allSanityCollection(filter: { shopify: { handle: { eq: $handle } } }, sort: { order: DESC, fields: _updatedAt }) {
      edges {
        node {
          metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
        }
      }
    }
    template: sanityTemplateCollection {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      additionalLoadMoreButton
      settingShowSubCollections
    }
  }
  fragment SanityCollectionFragment on SanityCollection {
    id
    url
    title
    descriptionHtml
    customTitle
    additionalTitle
    additionalContent
    image
    parentCollection: _rawParentCollection(resolveReferences: { maxDepth: 10 })
    relatedCollections: _rawRelatedCollections(resolveReferences: { maxDepth: 8 })
    shopify {
      id
      handle
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
