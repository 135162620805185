import React, { useMemo, useState } from "react"
import { BsChevronDown, BsChevronUp } from "react-icons/bs"
import { Provider, FilterStack, SensorStack } from "@usereactify/search"
import { Box, Button, Container, Flex, Heading, Text } from "@chakra-ui/react"

import config from "@root/config.default.js"

import { useAppContext } from "@app/providers/app"
import { useShopify } from "@app/hooks/useShopify"

import Link from "@app/components/Link"
import { SearchSort } from "@app/components/Search/Sort/SearchSort"
import { SearchResults } from "@app/components/Search/Results/SearchResults"
import { Filters } from "@app/components/Search/Filters/Filters"

import type { PageProps } from "@root/types/global"
import type { Props } from "@app/templates/collection"
import { RichText } from "../RichText"
import SelectedFilters from "@appbaseio/reactivesearch/lib/components/basic/SelectedFilters"
import { useRoutes } from "@app/hooks/useRoutes"
import { useBreadcrumb } from "@app/hooks/useBreadcrumb"

const Collection: React.FC<PageProps<Props>> = ({ location, collection }) => {
  const { activeCollection, setActiveCollection, activeStore } = useAppContext()
  const [count, setCount] = useState(0)
  const [readmore, setReadmore] = useState(false)
  const { collectionNormaliser } = useShopify()
  const { urlResolver } = useRoutes()
  const item = collectionNormaliser(collection)

  const readmoreContent = collection?.additionalContent?.split("\n") || []
  readmoreContent.unshift(item.descriptionHtml)

  const [isFilter, setFiltersVisible] = useState(false)

  //TODO: Replace with reactivesearch state
  const setActiveFilters = () => {
    //@ts-ignore
    const search = typeof window !== "undefined" ? window.location.search : ""
    const filterOptions = search.split("&")
    const filterSum = filterOptions
      .filter(option => !option.includes("page"))
      .map((option: string) => option?.split("%2C")?.length || 0)
      .reduce((a: number, b: number) => a + b, 0)
    const filterCount = filterOptions[0] !== "" ? filterSum : 0
    setCount(filterCount)
  }

  React.useEffect(() => {
    setActiveFilters()
    //@ts-ignore
  }, [])

  const toggleFilter = () => {
    setFiltersVisible(!isFilter)
    setActiveFilters()
  }

  React.useEffect(() => {
    if (item?.id !== activeCollection?.id) setActiveCollection(item)
  }, [item?.id, activeCollection?.id, setActiveCollection])

  const { buildBreadcrumbs } = useBreadcrumb(location)

  // Only keep title and url for breadcrumbs since keeping entire object causes lag when passed into navigation state in SearchResults
  const breadcrumbs = useMemo(
    () =>
      collection
        ? buildBreadcrumbs({ data: { collection } })
            ?.filter((_, index) => index !== 0)
            .map(breadcrumb => {
              return {
                title: breadcrumb.title,
                url: breadcrumb.url,
              }
            })
        : [],
    [collection]
  )

  const relatedCollections = useMemo(() => collection?.relatedCollections?.map(collectionNormaliser).map(urlResolver) || [], [collection])

  return (
    <Provider
      shopifyPermanentDomain={`${activeStore.shopName}.myshopify.com`}
      index={config.services.reactify.index ?? ""}
      collection={item}
      theme={{
        typography: {
          fontFamily: "inherit",
          fontSize: "inherit",
        },
        colors: {
          textColor: "inherit",
          primaryTextColor: "inherit",
          primaryColor: "inherit",
          titleColor: "inherit",
          alertColor: "inherit",
        },
      }}
    >
      <SensorStack />
      <Container as="section" maxW="container.xxl" px={[4, 16]} pb={[4, 12]}>
        <Box as="section" py={[4, 8]}>
          <Flex justifyContent="space-between" mb={[0, 8]}>
            <Box width={["100%", "33%"]}>
              <Heading as="h1" size="h1" textAlign={["left"]} maxW={["unset", "440px"]} mb={[0, 4]} mt={[0, -2]}>
                {collection?.customTitle || item?.title}
              </Heading>
            </Box>
            <Box width="66%" display={["none", "block"]}>
              <RichText html={item?.descriptionHtml} textStyle="paragraph2" size="14" maxWidth="680px" />
            </Box>
          </Flex>
          <Flex display={["none", "flex"]} justifyContent="space-between">
            <Box width={["100%", "33%"]}></Box>
            <Flex width="66%" wrap="wrap">
              {relatedCollections?.map(({ url, title }) => (
                <Text
                  key={title}
                  display={"block"}
                  py={1}
                  size="10"
                  fontWeight="700"
                  letterSpacing="0.2em"
                  as={props => <Link to={url} {...props} />}
                  textTransform="uppercase"
                  borderBottom="1px solid"
                  borderColor="transparent"
                  _hover={{
                    borderColor: "grey.500",
                  }}
                  marginRight={8}
                >
                  {title}
                </Text>
              ))}
            </Flex>
          </Flex>
        </Box>
        <Flex
          borderBottom="1px solid"
          borderColor="grey.200"
          pb={3}
          justifyContent="space-between"
          flexWrap={["wrap", "nowrap"]}
          gridGap={2}
        >
          <Flex alignItems="center" position="relative">
            <Flex alignItems="center" cursor="pointer" onClick={toggleFilter} letterSpacing={1}>
              <Text size="10" fontWeight="600" letterSpacing={"0.1em"}>
                FILTER
              </Text>
              <Text size="10" variant="gray" mx={[1, 2]} letterSpacing={"0.1em"}>
                ({count})
              </Text>
              {isFilter ? <BsChevronUp size="12" /> : <BsChevronDown size="12" />}
            </Flex>
            {count > 0 && (
              <SelectedFilters
                render={({ clearValues }) => (
                  <Button
                    variant="ghost"
                    fontSize="10px"
                    fontWeight="600"
                    letterSpacing={"0.1em"}
                    height="auto"
                    pr={0}
                    pl={4}
                    onClick={() => {
                      clearValues()
                      setCount(0)
                    }}
                  >
                    CLEAR FILTER
                  </Button>
                )}
              />
            )}
          </Flex>
          <Box d="flex" justifyContent={["flex-start", "flex-end"]} alignItems="flex-end" pb="3px">
            <Text mr="1" size="10" pb="1" letterSpacing={"0.1em"}>
              SORT BY:
            </Text>
            <SearchSort />
          </Box>
        </Flex>
        <Box display={isFilter ? "block" : "none"} transition="all 0.3s">
          <FilterStack render={filters => <Filters filters={filters} />} />
        </Box>
        <Flex mt={[4, 8]}>
          <Box w="100%">
            <SearchResults breadcrumbs={breadcrumbs} />
          </Box>
        </Flex>
        <Flex mb={[12, 24]}>
          <Box w={["100%", "66%"]}>
            <Heading as="h3" fontSize={["20", "18"]} fontWeight="700" lineHeight="26px" mb={5} w={["66%", "100%"]}>
              {collection?.additionalTitle}
            </Heading>
            {readmoreContent?.length ? (
              <>
                <Box mb="12">
                  <Box d={["block", "none"]}>
                    {readmoreContent.map((content, index) => (
                      <Box
                        key={index}
                        className="rte"
                        mt={index === 1 ? 10 : 0}
                        d={index == 0 || readmore ? "block" : "none"}
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    ))}
                  </Box>
                  <Box d={["none", "block"]}>
                    {readmoreContent.slice(1).map((content, index) => (
                      <Box
                        key={index}
                        className="rte"
                        d={index <= 1 || readmore ? "block" : "none"}
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                    ))}
                  </Box>
                </Box>
                {!readmore && readmoreContent.length > 2 ? (
                  <Button variant="outline" fontSize="14" lineHeight="20px" onClick={() => setReadmore(true)}>
                    Read More
                  </Button>
                ) : null}
              </>
            ) : null}
          </Box>
        </Flex>
      </Container>
    </Provider>
  )
}

export default Collection
